<template>
  <div v-loading="loading" style="min-height: 600px" class="elv-reconciliation-match-sets-container">
    <div class="elv-reconciliation-match-sets-header">
      <span>{{ t('report.matchSets') }}</span>
      <div class="elv-reconciliation-match-sets-header__operation">
        <elv-export-button
          :params="{
            type: 'RECONCILIATION',
            extra: { type: 'MATCH_SETS' }
          }"
          :no-permission="false"
        />
        <elv-button height="32" width="132" round plain type="primary" @click="onCreateMatchSet"
          ><SvgIcon name="source-add" width="16" height="16" />{{ t('button.newMatchSet') }}</elv-button
        >
      </div>
    </div>

    <ListTable
      :params="matchParams"
      :table-height="tableHeight"
      :table-data="reconciliationStore.reconciliationSetList"
      :table-loading="tableLoading"
      @on-change-page="onChangePage"
      @onEditMatchSet="onEditMatchSet"
      @on-change-page-size="onChangePageSize"
    />

    <MatchSetDialog
      v-model:show="showMatchSetDialog"
      :model="matchSetDialogModel"
      :current-data="currentMatchSet"
      @onResetList="onChangePage(1)"
    />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import ListTable from './components/ListTable.vue'
import { useEntityStore } from '@/stores/modules/entity'
import MatchSetDialog from './components/MatchSetDialog.vue'
import ElvExportButton from '@/components/Base/ElvExportButton.vue'
import { useReconciliationStore } from '@/stores/modules/reconciliation'

dayjs.extend(utc)
dayjs.extend(timezone)
const route = useRoute()
const entityStore = useEntityStore()
const reconciliationStore = useReconciliationStore()

const loading = ref(true)
const tableLoading = ref(false)
const showMatchSetDialog = ref(false)
const matchSetDialogModel = ref('add')
const currentMatchSet: any = ref({})

const matchParams = ref({
  page: 1,
  limit: 20
})

const { t } = useI18n()

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const tableHeight = computed(() => {
  return `calc(100% - 40px)`
})

const getMatchSetsTableData = async () => {
  try {
    tableLoading.value = true
    await reconciliationStore.fetchReconciliationSetList(entityId.value, matchParams.value)
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

const onChangePageSize = (limit: number) => {
  matchParams.value.limit = limit
  matchParams.value.page = 1
  getMatchSetsTableData()
}

const onChangePage = (page: number) => {
  matchParams.value.page = page
  getMatchSetsTableData()
}

const reconciliationDataInit = async () => {
  try {
    loading.value = true
    await getMatchSetsTableData()
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const onCreateMatchSet = () => {
  matchSetDialogModel.value = 'add'
  showMatchSetDialog.value = true
  currentMatchSet.value = {}
}

const onEditMatchSet = (currentData: any) => {
  currentMatchSet.value = currentData
  matchSetDialogModel.value = 'edit'
  showMatchSetDialog.value = true
}

watch(
  [() => route, () => entityStore.entityDetail?.entityId],
  async () => {
    try {
      if (route.name === 'entity-reconciliation-match-sets' && entityStore.entityDetail?.entityId === entityId.value) {
        await reconciliationDataInit()
      }
    } catch (error) {
      console.log(error)
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped>
.elv-reconciliation-match-sets-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.elv-reconciliation-match-sets-header {
  width: 100%;
  padding: 0 29px 8px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: #000;
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .elv-reconciliation-match-sets-header__operation {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.elv-reconciliation-match-sets-empty-content {
  margin-top: 180px;

  .elv-result-description {
    margin-top: 12px;

    p {
      color: #000;
      font-family: 'Plus Jakarta Sans';
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
}
</style>
