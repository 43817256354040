<template>
  <div v-if="props.data || props.data === 0" class="elv-table-row__number">
    {{
      props.data === 0
        ? 0
        : props.type === 'number'
          ? formatNumber(props.data)
          : fieldValueFormat(
              props.data,
              {
                price: true,
                symbol: `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
              },
              'NUMBER'
            )
    }}
  </div>
  <div v-else class="elv-table-row__number-empty v-else">-</div>
</template>

<script setup lang="ts">
import { useEntityStore } from '@/stores/modules/entity'
import { formatNumber, fieldValueFormat } from '@/lib/utils'

const props = defineProps({
  data: {
    type: Number,
    default: 0
  },
  type: {
    type: String,
    default: 'number' // number | price
  }
})

const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
</script>

<style lang="scss" scoped>
.elv-table-row__number {
  color: #0e0f11;
  font-family: 'Barlow';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  white-space: nowrap;
}

.elv-table-row__number-empty {
  color: #838d95;
  font-family: 'Barlow';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>
