<template>
  <div class="elv-table-row__avatar">
    <VueBlockies
      style="border-radius: 50%"
      :seed="md5UserParams"
      :color="changeAvatarColor(md5UserParams)[0]"
      :bgcolor="changeAvatarColor(md5UserParams)[1]"
      :size="7"
      :scale="4"
      :alt="props.params?.data?.name"
      spot-color="#666"
    />
    <span>{{ props.params?.data?.name }}</span>
  </div>
</template>

<script setup lang="ts">
import md5 from 'js-md5'
import VueBlockies from 'vue-blockies'
import { avatarColor } from '@/config/index'

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const md5UserParams = computed(() => {
  const md5UserId = props.params?.data?.userId?.slice(0, 18) || ''
  return md5(md5UserId)
})

const changeAvatarColor: any = (val: string) => {
  const lastStr = val?.substr(val.length - 1, 1) || ''
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(Number(lastStr))) {
    return changeAvatarColor(val?.substr(0, val.length - 1))
  }
  return avatarColor[lastStr]
}
</script>

<style lang="scss" scoped>
.elv-table-row__avatar {
  display: flex;
  align-items: center;

  img {
    width: 18px;
    height: 18px;
    display: block;
    margin-right: 8px;
  }
}
</style>
